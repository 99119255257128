const htmlEl = document.querySelector('html');
const searchToggle = document.querySelector('.search-toggle');
const searchInput = document.querySelector('.search-form__input');
const searchClose = document.querySelector('.search-close');

const checkIfEscape = (e) => {
	if (e.key === 'Escape') {
		closeSearch();
	}
};

const openSearch = () => {
	htmlEl.classList.add('search-open');
	searchInput.focus();
	document.addEventListener('keydown', checkIfEscape);
};

const closeSearch = () => {
	htmlEl.classList.remove('search-open');
	searchToggle.focus();
	document.removeEventListener('keydown', checkIfEscape);
};

if (searchToggle) {
	searchToggle.addEventListener('click', () => {
		openSearch();
	});
}

if (searchClose) {
	searchClose.addEventListener('click', () => {
		closeSearch();
	});
}
